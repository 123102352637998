'use client'
/* eslint-disable import/no-named-as-default */

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/ui/form'
import { Select, SelectTrigger, SelectValue } from '@components/ui/select'
import {
  SelectCountryContent,
  zodCountryOptions,
} from '@components/form/select-country'
import { type SignUpResponse } from '@app/api/sign-up/route'
import { type SignUpRequest } from '@lib/types'

import FormTermsAndConditions from '@components/form/terms-and-conditions'
import { Input } from '@components/ui/input'
import { Switch } from '@components/ui/switch'
import { Toaster } from '@components/ui/sonner'
import { toast } from 'sonner'
import { useForm } from 'react-hook-form'
import { useUtmInitialValues } from '@components/form/utm-fields'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import Button from '@components/interaction/button'
import { type CSSProperties, Suspense, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import logger from '@config/logger'
import { executeRecaptcha } from '@lib/recaptcha-client'
import { Check } from 'lucide-react'
import { cn } from '@lib/utils'
import Recaptcha from '@components/form/recaptcha'
import { sendGTMEvent } from '@next/third-parties/google'
import { type PosthogPerson, StandardEvent } from '@lib/analytics'
import posthog from 'posthog-js'

interface ThemeColors {
  inputBorder?: string
  inputBackground?: string
  buttonVariant: 'contained' | 'outlined'
  buttonColour: 'secondary' | 'secondary-contrast'
}

const themes: Record<'light' | 'dark', ThemeColors> = {
  light: {
    buttonVariant: 'contained',
    buttonColour: 'secondary',
  },
  dark: {
    buttonVariant: 'outlined',
    buttonColour: 'secondary-contrast',
    inputBorder: '[&]:border-l-0 [&]:border-r-0 [&]:border-t-0',
    inputBackground: '[&]:bg-black',
  },
}

export const SignUp = ({ className }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('h-fit py-16', className)}>
    <div className='justify-between lg:flex'>
      <div className='text-left text-[40px] text-zinc-800 max-lg:mb-4 lg:max-w-sm'>
        <p>Want the latest insights straight to your inbox?</p>
        <h2 className='mt-2'>Sign up.</h2>
      </div>
      <Suspense>
        <SignUpForm
          formClassName='p-5 sm:p-8 lg:p-10 xl:p-12 md:max-w-2xl lg:max-w-xl'
          inputContainerClassName='grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-5'
        />
      </Suspense>
    </div>
  </div>
)

export const SignUpInline = ({
  className,
  theme = 'light',
}: React.HTMLAttributes<HTMLDivElement> & { theme?: 'dark' | 'light' }) => {
  const overrideFormCssVariables =
    theme === 'light'
      ? undefined
      : ({
          '--ring': '0 100% 100%', // white - :focus-visible ring
        } as CSSProperties)
  return (
    <div
      style={overrideFormCssVariables}
      className={cn('h-fit', { dark: theme === 'dark' }, className)}
    >
      <div className='flex flex-col justify-between'>
        <div>
          <p className='text-xl font-bold'>
            Want the latest insights straight to your inbox?
          </p>
        </div>
        <Suspense>
          <SignUpForm
            theme={theme}
            formClassName='pt-6'
            inputContainerClassName='flex flex-col gap-6'
          />
        </Suspense>
      </div>
    </div>
  )
}

const formSchema = z.object({
  firstName: z.string().min(2, 'First name is required').max(50),
  lastName: z.string().min(2, 'Last name is required').max(50),
  email: z.string().email(),
  jobTitle: z.string().min(2, 'Job title is required').max(50),
  company: z.string().optional(),
  country: zodCountryOptions,
  subscribe: z.boolean(),
})

function SignUpForm({
  theme = 'light',
  formClassName,
  inputContainerClassName,
}: {
  theme?: 'dark' | 'light'
  formClassName?: string
  inputContainerClassName?: string
}) {
  const utmInitialValues = useUtmInitialValues()
  const [loadRecaptcha, setLoadRecaptcha] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [signupSucceeded, setSignUpSucceeded] = useState(false)
  const searchParams = useSearchParams()
  const debug = searchParams.get('debug')

  const themeColors = themes[theme]

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      jobTitle: '',
      company: '',
      country: '',
      subscribe: true,
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setSubmitting(true)

    try {
      const token = await executeRecaptcha(StandardEvent.SignUp)

      if (!token) {
        toast.error('Failed to Send', {
          description: 'We could not verify that you are not a bot.',
          action: {
            label: 'Close',
            onClick: () => null,
          },
        })
        setSubmitting(false)
        return
      }

      const person: PosthogPerson = values
      posthog.identify(values.email, person)

      const data: SignUpRequest = {
        ...utmInitialValues,
        ...values,
        token,
      }

      if (debug === 'true') {
        toast('Debug: SignUpRequest', {
          description: (
            <pre className='mt-2 max-w-80 overflow-x-scroll rounded-md bg-slate-950 p-4'>
              <code className='text-white'>
                {JSON.stringify(data, null, 2)}
              </code>
            </pre>
          ),
        })
      }

      await postSignUpForm(data)
      posthog.capture(StandardEvent.SignUp, {
        subscribe: data.subscribe,
        ...utmInitialValues,
      })
      sendGTMEvent({ event: StandardEvent.SignUp })
      setSignUpSucceeded(true)
    } catch (error) {
      if (debug === 'true') {
        logger.error(error)
      }
      toast.error('Failed to Send', {
        description: 'Something went wrong. Please try again.',
        action: {
          label: 'Close',
          onClick: () => null,
        },
      })
    }
    setSubmitting(false)
  }

  return (
    <Form {...form}>
      {loadRecaptcha && <Recaptcha />}
      <form
        id={StandardEvent.SignUp}
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn('mx-auto h-fit shadow-md lg:mr-0', formClassName)}
      >
        <div className={inputContainerClassName}>
          <FormField
            control={form.control}
            name='firstName'
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    className={cn(
                      themeColors.inputBorder,
                      themeColors.inputBackground,
                    )}
                    autoComplete='given-name'
                    placeholder='First name *'
                    onFocus={() => {
                      setLoadRecaptcha(true)
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='lastName'
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    className={cn(
                      themeColors.inputBorder,
                      themeColors.inputBackground,
                    )}
                    autoComplete='family-name'
                    placeholder='Last name *'
                    onFocus={() => {
                      setLoadRecaptcha(true)
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    className={cn(
                      themeColors.inputBorder,
                      themeColors.inputBackground,
                    )}
                    autoComplete='email'
                    placeholder='Email *'
                    onFocus={() => {
                      setLoadRecaptcha(true)
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='jobTitle'
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    className={cn(
                      themeColors.inputBorder,
                      themeColors.inputBackground,
                    )}
                    autoComplete='organization-title'
                    placeholder='Job title *'
                    onFocus={() => {
                      setLoadRecaptcha(true)
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='company'
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    className={cn(
                      themeColors.inputBorder,
                      themeColors.inputBackground,
                    )}
                    autoComplete='organization'
                    placeholder='Company name'
                    onFocus={() => {
                      setLoadRecaptcha(true)
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='country'
            render={({ field }) => (
              <FormItem>
                <Select
                  aria-label={field.name}
                  name={field.name}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      className={cn(
                        themeColors.inputBorder,
                        themeColors.inputBackground,
                      )}
                    >
                      <SelectValue
                        placeholder='Country *'
                        onFocus={() => {
                          setLoadRecaptcha(true)
                        }}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectCountryContent />
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name='subscribe'
          render={({ field }) => (
            <FormItem className='mx-2 my-6 flex flex-row items-center space-x-3'>
              <FormControl>
                <Switch
                  className={cn({
                    '[&]:data-[state=checked]:bg-gold': theme === 'dark',
                  })}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  onFocus={() => {
                    setLoadRecaptcha(true)
                  }}
                />
              </FormControl>
              <FormLabel>
                I would like to receive marketing communications regarding V2
                insights, services and events.
              </FormLabel>
            </FormItem>
          )}
        />
        <Button
          disabled={submitting || signupSucceeded}
          variant={themeColors.buttonVariant}
          size='md'
          loading={submitting}
          colour={themeColors.buttonColour}
          type='submit'
        >
          {signupSucceeded ? (
            <div className='flex items-center'>
              <span className='mr-2'>Signed Up</span> <Check />
            </div>
          ) : submitting ? (
            'Submitting...'
          ) : (
            'Sign Up'
          )}
        </Button>
        <FormTermsAndConditions submitButtonLabel='Sign Up' />
      </form>
      <Toaster />
    </Form>
  )
}

const postSignUpForm = async (data: SignUpRequest): Promise<void> => {
  const response = await fetch('/api/sign-up', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  const responseData: SignUpResponse = await response.json()

  if (response.ok) {
    toast('Sign up request submitted', {
      description: responseData.message,
      action: {
        label: 'Close',
        onClick: () => null,
      },
    })
  } else {
    throw new Error(responseData.message)
  }
}
